<template>
    <div class="public-layout">
        <modal-loader />
        <div class="login-page">
            <img
                class="logo"
                :src="require('@/assets/images/intratone/logo.jpg')"
            />
            <basic-button
                color="unset"
                class="burger-menu"
                :class="displayMenu && 'animate'"
                @click="displayMenu = !displayMenu"
            >
                <i class="fas fa-bars"></i>
            </basic-button>
            <language-selector></language-selector>
            <router-view></router-view>
        </div>
        <ul class="navigation" :class="displayMenu && 'animate'">
            <li
                v-for="item in links"
                :key="item.title"
                @click="displayMenu = false"
            >
                <a :href="item.link" target="_blank">
                    <img :src="item.icon" />
                    <span>{{ item.title }}</span>
                </a>
            </li>
            <li>
                <router-link to="/support" @click="displayMenu = false">
                    <img :src="require('@/assets/images/icons/grid.svg')" />
                    <span>{{ $t("publicHome.support") }}</span>
                </router-link>
            </li>
        </ul>
        <div class="image-container">
            <div class="image-wrapper">
                <img
                    class="image-residence"
                    :src="require('@/assets/images/buildings/city-large.png')"
                    alt="residence"
                    width="680"
                    height="680"
                />
            </div>
        </div>
        <div class="reload" v-if="displayReload">
            <div>
                {{ $t("alerts.version.new_version") }}
            </div>
            <div class="reload-page" @click="reload">
                <i class="fas fa-sync-alt"></i>
                {{ $t("alerts.version.reload") }}
            </div>
        </div>
    </div>
</template>

<script>
import LanguageSelector from "@/components/LanguageSelector.vue"
import redirect from "@/mixins/redirection.js"
import BasicButton from "@/components/basic/BasicButton.vue"
import ModalLoader from "@/components/Modal/ModalLoader"
import navigation from "@/mixins/navigation"

import { getEnvironment } from "@/helpers/getEnvironment.js"

export default {
    name: "PublicLayout",
    mixins: [redirect, navigation],
    components: {
        LanguageSelector,
        ModalLoader,
        BasicButton,
    },
    data() {
        return {
            displayMenu: false,
            displayReload:
                window.location.href.search("reload=true") > 0 &&
                window.location.href.search("manuel=true") < 0,
            links: [
                {
                    title: this.$t("publicHome.residenceTransfer"),
                    link: this.getTransfert(),
                    icon: require("@/assets/images/icons/residence.svg"),
                },
                {
                    title: this.$t("publicHome.manuals"),
                    link: this.getNotices(),
                    icon: require("@/assets/images/icons/badges.svg"),
                },
                {
                    title: this.$t("publicHome.returnCoupon"),
                    link: this.getBonSAV(),
                    icon: require("@/assets/images/icons/loading.svg"),
                },
                {
                    title: this.$t("publicHome.programmerSetup"),
                    link: "https://home.intratone.info/v2/?&r=1#/setup",
                    icon: require("@/assets/images/icons/parking.svg"),
                },
                {
                    title: this.$t("publicHome.terms"),
                    link: this.getConditions(),
                    icon: require("@/assets/images/icons/message.svg"),
                },
                // {
                //     title: this.$t('publicHome.support'),
                //     link: "/support",
                //     icon: require("@/assets/images/icons/grid.svg")
                // }
            ],
        }
    },
    methods: {
        reload() {
            window.history.pushState(
                {},
                "Intratone - Site de gestion",
                `${window.location.href}&manuel=true`
            )
            this.totalClear()
        },
    },
    mounted() {
        this.$store.commit("auth/setParam", getEnvironment())
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.public-layout {
    display: flex;
}

.reload {
    position: absolute;
    background-color: $blue-lighter;
    color: $white;
    width: 100vw;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 20px;
    top: calc(100vh - 50px);
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: 0.05em;
    font-size: 14px;
    font-family: "Avenir Roman";
    .reload-page {
        cursor: pointer;
        color: white;
        background-color: $orange;
        padding: 10px;
        border-radius: 10px;
    }
}

.login-page {
    height: 100vh;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow-y: auto;
}

.logo {
    pointer-events: none;
}

@media all and (min-width: 1024px) {
    .burger-menu {
        display: none;
    }
    .navigation li {
        margin-bottom: 10px;
    }
    .navigation li a {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .navigation li:last-child {
        margin-bottom: 0;
    }
    .navigation span {
        font-family: $font_avenir_heavy;
        font-size: $small;
        line-height: 13px;
    }
    .navigation a {
        border-radius: 0 30px 30px 0;
        color: $white;
        font-weight: 500;
        line-height: 1.08;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px 10px;
        font-size: $normal;
        text-decoration: none;
        -webkit-transition: 0.1s ease-in all;
        transition: 0.1s ease-in all;
    }
    .navigation a:hover {
        background-color: $blue-lighter;
        //padding-left: 20px;
    }
    .navigation a img {
        margin-bottom: 10px;
    }
    .navigation {
        //background-color: red;
        transition: 0.25s ease-in all;
        -webkit-transition: 0.25s ease-in all;
        list-style: none;
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-content: center;
        //width: 100%;
        //height: 100vh;
        padding: unset;
        margin: unset;
        li {
            width: 200px;
            //background-color: blue;
            a {
                width: 150px;
                height: 100px;
                font-size: $small;
                //padding: 28px 20px;
                background-color: $blue-light;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                //background-color: green;
                span {
                    width: 130px;
                }
            }
            a:hover {
                width: 175px;
                padding-left: 25px;
            }
        }
    }

    .image-container {
        display: flex;
        flex-grow: 1;

        .image-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .image-residence {
                pointer-events: none;
                width: 85%;
                max-width: 550px;
                height: auto;

                @media all and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }

    .login-page {
        width: 50%;
        max-width: 800px;
        min-width: 600px;
        border-radius: 0 50px 50px 0;
    }
}
@media all and (max-width: 1024px) {
    .image-residence {
        display: none;
    }
    .public-layout {
        width: 100%;
    }
    .login-page {
        width: 100%;
        max-width: unset;
        min-width: 100%;
        border-radius: unset;
    }
    .login-page {
        border-radius: unset;
    }
    .burger-menu {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: $veryBig;
        color: $blue-neutral;
        z-index: 20;
    }
    .burger-menu.animate {
        color: $white;
    }
    .navigation {
        display: none;
    }
    .navigation.animate::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
    .navigation.animate {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        align-content: center;
        width: 100vw;
        background-color: $blue-light;
        z-index: 10;
        animation-name: glissement;
        animation-duration: 0.1s;
        animation-fill-mode: forwards;
        padding-top: 50px;
        height: 100vh;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow: scroll;
        li {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-bottom: 20px;
            margin-top: 20px;
            a {
                color: $white;
                font-weight: 500;
                line-height: 1.08;
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                font-size: $normal;
                text-decoration: none;
                -webkit-transition: 0.1s ease-in all;
                transition: 0.1s ease-in all;
            }
            a:hover {
                background-color: $blue-lighter;
                padding-left: 20px;
            }
            a img {
                margin-bottom: 10px;
                //background-color: red;
                width: 7vw;
                height: 7vw;
            }
            a span {
                margin-left: 20px;
                font-size: $big;
                //background-color: red;
            }
        }
        li:last-child {
            margin-bottom: 0;
        }
    }
}
@keyframes glissement {
    from {
        margin-left: 100%;
    }
    to {
        margin-left: -100%;
        display: block;
    }
}
</style>
