export default [
    {
        path: '/',
        name: 'Home',
        redirect: '/residences'
    },
    {
        path: '/residences',
        name: 'Residences',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_KEY', 'G_KEY_MOBIL', 'G_CLEPASS', 'G_FICHES', 'G_APP', 'G_APP_NOM', 'G_KEY_MOVE', 'G_NAME_MOVE', 'G_RESIDENCES', 'G_KIBOLT', 'G_TAGIT', 'G_CONNECTIT', 'G_CODE_TEL', 'G_CODE_CLAV'],
            tab: 'residences'
        },
        component: () => import('@/views/residence/List.vue')
    },
    {
        path: '/residences/create',
        name: 'CreateResidences',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_RESIDENCES'],
            tab: 'residences'
        },
        component: () => import('@/views/residence/CreateOrEdit.vue')
    },
    {
        path: '/residences/:id/edit',
        name: 'EditResidence',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_RESIDENCES'],
            tab: 'residences'
        },
        component: () => import('@/views/residence/CreateOrEdit.vue')
    },
    {
        path: '/residence/:id(\\d+)/:tab(details|authorizations|codes|central-units|subaccounts)?',
        name: 'Residence',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_KEY', 'G_KEY_MOBIL', 'G_CLEPASS', 'G_FICHES', 'G_APP', 'G_APP_NOM', 'G_KEY_MOVE', 'G_NAME_MOVE', 'G_RESIDENCES', 'G_KIBOLT', 'G_TAGIT', 'G_CONNECTIT', 'G_CODE_TEL', 'G_CODE_CLAV'],
            entity: 'residence',
            tab: 'residences'
        },
        component: () => import('@/views/residence/Details.vue')
    },
    {
        path: '/stairwell/:id(\\d+)',
        name: 'Stairwell',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_KEY', 'G_KEY_MOBIL', 'G_CLEPASS', 'G_FICHES', 'G_APP', 'G_APP_NOM', 'G_KEY_MOVE', 'G_NAME_MOVE', 'G_RESIDENCES', 'G_KIBOLT', 'G_TAGIT', 'G_CONNECTIT', 'G_CODE_TEL', 'G_CODE_CLAV'],
            entity: 'stairwell',
            tab: 'residences'
        },
        component: () => import('@/views/stairwell/Details.vue')
    },
    {
        path: '/building/:id(\\d+)',
        name: 'Building',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_KEY', 'G_KEY_MOBIL', 'G_CLEPASS', 'G_FICHES', 'G_APP', 'G_APP_NOM', 'G_KEY_MOVE', 'G_NAME_MOVE', 'G_RESIDENCES', 'G_KIBOLT', 'G_TAGIT', 'G_CONNECTIT', 'G_CODE_TEL', 'G_CODE_CLAV'],
            entity: 'building',
            tab: 'residences'
        },
        component: () => import('@/views/building/Details.vue')
    },
    {
        path: '/apartment/:id(\\d+)',
        name: 'Apartment',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_KEY', 'G_KEY_MOBIL', 'G_CLEPASS', 'G_FICHES', 'G_APP', 'G_APP_NOM', 'G_KEY_MOVE', 'G_NAME_MOVE', 'G_RESIDENCES', 'G_KIBOLT', 'G_TAGIT', 'G_CONNECTIT'],
            entity: 'apartment',
            tab: 'residences'
        },
        component: () => import('@/views/apartment/Details.vue')
    },
    {
        path: '/apartment',
        name: 'Apartments',
        meta: {
            layout: 'PrivateLayout',
            authorize: ['G_KEY', 'G_KEY_MOBIL', 'G_CLEPASS', 'G_FICHES', 'G_APP', 'G_APP_NOM', 'G_KEY_MOVE', 'G_NAME_MOVE', 'G_RESIDENCES', 'G_KIBOLT', 'G_TAGIT', 'G_CONNECTIT'],
            entity: 'apartment',
            tab: 'residences'
        },
        component: () => import('@/components/entities/ApartmentsList.vue')
    }
]
