const state = () => ({
    countries: [],
    industryFields: [],
    languages: []
})

// getters
const getters = {
    getAllCountries: state => {
        return state.countries
    },
    getAllFormatedCountries: state => {
        const formatedCountries = []
        state.countries.forEach((element) => {
            formatedCountries.push({
                name: element.translate,
                value: element.id
            })
        })
        return formatedCountries
    },
    getCountryByCode: state => code => {
        return state.countries.find(element => element.code.toLowerCase() === code.toLowerCase()).img
    },
    getAllIndustryFields: state => {
        return state.industryFields
    },
    getAllFormatedIndustryFields: state => {
        const FormatedIndustryFields = []
        state.industryFields.forEach((element) => {
            FormatedIndustryFields.push({
                name: element.translate,
                value: element.id
            })
        })
        return FormatedIndustryFields
    }
}

// actions
const actions = {}

// mutations
const mutations = {
    setCountries(state, value) {
        state.countries = value
    },
    setIndustryFields(state, value) {
        state.industryFields = value
    },
    setLanguages(state, value) {
        state.languages = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}