const state = () => ({
    breadCrumb: [],
})

const getters = {
    getBreadCrumb: (state) => {
        return state.breadCrumb
    },
}

const actions = {}

const mutations = {
    setBreadCrumb(state, data) {
        state.breadCrumb = data
    },

    pushBreadCrumb(state, data) {
        state.breadCrumb.push(...data)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
