import { createApp } from "vue"
import router from "./router"
import store from "./store"
import axios from "axios"
import VueAxios from "vue-axios"
import App from "./App.vue"
import i18n from "./i18n"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import "./../src/assets/scss/toast.scss"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faEye } from "@fortawesome/free-regular-svg-icons"
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons"
import { faStar } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { Form, Field, ErrorMessage } from "vee-validate"
import mixinRight from "./mixins/right.js"
import mixinModal from "./mixins/modal.js"
import mitt from "mitt"
import vClickOutside from "click-outside-vue3"

const app = createApp(App)
const emitter = mitt()

// ACTUALISE IF NEW VERSION
router.onError((error) => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload()
    }
})

app.use(router)

app.use(i18n)
app.use(VueAxios, axios)

app.use(store)
app.use(vClickOutside)

app.mixin(mixinRight)
app.mixin(mixinModal)

app.use(Toast, {
    position: "top-right",
    transition: "Vue-Toastification__fade",
    toastClassName: "customToast",
    maxToasts: 4,
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
})

app.component("VForm", Form)
app.component("VField", Field)
app.component("VErrorMessage", ErrorMessage)

library.add(faEye)
library.add(faEyeSlash)
library.add(faStar)

app.component("fai", FontAwesomeIcon)
app.component("font-awesome-icon", FontAwesomeIcon)

app.config.globalProperties.emitter = emitter
app.provide("emitter", emitter)

app.directive("no-tooltip", {
    mounted(el) {
        if (el.hasAttribute("title")) {
            el.removeAttribute("title")
        }
    },
    updated(el) {
        if (el.hasAttribute("title")) {
            el.removeAttribute("title")
        }
    },
})

app.mount("#app")
