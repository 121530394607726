<template>
    <div class="copie-day-time-range" ref="modal">
        <div class="form-choice">
            <div class="force-copie">
                <div class="select">
                    <span>{{ $t("vigik.modale.copySchedule.copyTo") }}</span>
                    <BasicSelect
                        class="day-select"
                        :options="formatedDays"
                        :needDefault="false"
                        v-model="choiceDay"
                        @update:modelValue="change"
                    />
                </div>
                <span>{{ $t("vigik.modale.copySchedule.onNextDays") }}</span>
            </div>
            <div class="options">
                <div
                    v-for="item in days"
                    :key="`check-day-${item.value}`"
                    class="items"
                >
                    <BasicButton
                        classStyle="btn-checkbox"
                        @click="
                            item.value === choiceDay
                                ? null
                                : checkDay(item.value)
                        "
                        :color="
                            item.value === choiceDay
                                ? 'grey'
                                : copyDays[item.value] === item.value
                                ? 'blue'
                                : 'white'
                        "
                        >{{ $t(`${item.name}`).slice(0, 1) }}</BasicButton
                    >
                </div>
            </div>
        </div>
        <div class="actions">
            <BasicButton
                @click="isDaysSelected ? save() : null"
                :disabled="!isDaysSelected"
            >
                {{ $t("button.register") }}
            </BasicButton>
            <basic-button @click="close" color="blue">
                {{ $t("button.cancel") }}
            </basic-button>
        </div>
    </div>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"
import BasicSelect from "@/components/basic/BasicSelect.vue"

export default {
    name: "CopieDayTimeRange",
    components: { BasicButton, BasicSelect },
    props: {
        days: {
            type: Array,
            required: true,
        },
        selectedDay: {
            type: Number,
            required: false,
        },
        saveCopy: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            day: null,
            choiceDay: null,
            formatedDays: [],
            copyDays: [],
        }
    },
    mounted() {
        this.choiceDay = this.selectedDay
        this.deleteTitle()
        this.formateDays(this.days)
    },
    computed: {
        isDaysSelected() {
            return this.copyDays.some((element) => element !== null)
        },
    },
    methods: {
        deleteTitle() {
            if (this.$refs.modal.getAttribute("title")) {
                this.$refs.modal.removeAttribute("title")
            }
        },
        change(value) {
            this.choiceDay = parseInt(value)
        },
        formateDays(days) {
            days.forEach((day) => {
                this.formatedDays.push({
                    name: this.$t(`${day.name}`),
                    value: day.value,
                })
            })
        },
        checkDay(day) {
            if (this.copyDays[day] === day) {
                delete this.copyDays[day]
            } else {
                this.copyDays[day] = day
            }
        },
        save() {
            this.saveCopy({
                originDay: this.choiceDay,
                days: this.copyDays,
            })
        },
        close() {
            this.closeModal()
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.copie-day-time-range {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: $normal;

    @media all and (max-width: 768px) {
        width: 100%;
    }
    .form-choice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 20px;
        .options {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            flex-wrap: nowrap;
            gap: 0.7em;

            .items {
                .form-check-label {
                    padding-left: 10px;
                }
            }
        }
        .force-copie {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            gap: 20px;
            width: 100%;
            span {
                font-size: $small;
                font-family: $font-avenir-medium;
            }
            .select {
                display: flex;
                flex-direction: row;

                align-items: center;
                align-content: center;
                gap: 0.5em;
                width: 100%;

                span {
                    width: 30%;
                }
                .day-select {
                    width: 70%;
                }
            }
        }
    }
    .actions {
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;
        gap: 2em;
    }
}
</style>
