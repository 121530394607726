import store from "@/store"

const mixins = {
    methods: {
        totalClear(fullClear = true) {
            store.commit("disconnect")
            localStorage.clear()
            window.Deconnect(1)
            if (fullClear) {
                window.location.reload(true)
            }
        },
    },
}
export default mixins
