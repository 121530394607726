<template>
    <div class="validation-modal" ref="modal">
        <div class="question">{{ question }}</div>
        <div class="actions">
            <basic-button color="orange" @click="accept">
                {{ validateButtonText }}
            </basic-button>
            <basic-button color="blue" @click="close">
                {{ cancelButtonText }}
            </basic-button>
        </div>
    </div>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"

export default {
    name: "Validation",
    components: { BasicButton },
    props: {
        question: {
            type: String,
            required: true,
        },
        valid: {
            type: Function,
            required: false,
        },
        refuse: {
            type: Function,
            required: false,
        },
        validateButton: {
            type: String,
            required: false,
            default: null,
        },
        cancelButton: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        validateButtonText() {
            return this.validateButton
                ? this.validateButton
                : this.$t("button.validate")
        },
        cancelButtonText() {
            return this.cancelButton
                ? this.cancelButton
                : this.$t("button.cancel")
        },
    },
    mounted() {
        this.deleteTitle()
    },
    methods: {
        deleteTitle() {
            if (this.$refs.modal.getAttribute("title")) {
                this.$refs.modal.removeAttribute("title")
            }
        },
        close() {
            if (this.refuse) {
                this.refuse()
            }
            this.closeModal()
        },
        accept() {
            this.valid()
            this.closeModal()
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/fontSize.scss";
@import "@/assets/scss/global.scss";
.validation-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    gap: 1em;
    .question {
        font-size: $normal;
        text-align: center;
        width: 100%;
        font-family: $font-avenir-medium;
    }
    .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 1em;
    }
}
</style>
