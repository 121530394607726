import { mapState } from 'vuex'

const mixins = {
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      defines: state => state.account.define,
      privileges: state => state.auth.privileges
    })
  },
  methods: {
    /**
     * @param right {string || array}
     * @returns {boolean}
     */
    checkRight (right) {
      let isValid = true
      if (this.rights.find(e => e === 'ADMIN')) {
        return isValid
      } else {
        let researchArray = []
        if (typeof right === 'string') {
          researchArray.push(right)
        } else {
          researchArray = right
        }
        researchArray.forEach((rightNeed) => {
          if (!this.rights.find(e => e === rightNeed)) {
            isValid = false
          }
        })
        return isValid
      }
    },

    /**
     * @param rights {array}
     * @returns {boolean}
     */
    checkRightWithOr (rights) {
      let condition = false
      if(rights.length > 0) {
        rights.forEach((right) => {
          if(this.checkRight(right)) {
            condition = true
          }
        })
      } else {
        condition = true
      }
      return condition
    },
    checkDefineWithOr (defines) {
      let condition = false
      if(defines.length > 0) {
        defines.forEach((define) => {
          if (this.checkDefine(define)) {
            condition = true
          } else {
            return false
          }
        })
      } else {
        condition = true
      }
      return condition
    },
    checkDefineWithAnd (defines) {
      const total_count = defines.length
      let checked_define = 0
      if(defines.length > 0) {
        defines.forEach((define) => {
          if (this.checkDefine(define)) {
            checked_define++
          }
        })
      }
      return total_count===checked_define
    },
    checkDefine (define) {
      if (define !== '' && this.defines !== undefined) {
        return !!parseInt(this.defines[define])
      }
    },
    checkPrivilege (privilege) {
      let isValid = true
      let researchArray = []
        if (typeof privilege === 'string') {
          researchArray.push(privilege)
        } else {
          researchArray = privilege
        }
        researchArray.forEach((privilegeNeed) => {
          if (!this.privileges.find(e => e === privilegeNeed)) {
            isValid = false
          }
        })
        return isValid
    }
  }
}
export default mixins
