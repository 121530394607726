import i18n from "../i18n"

export function getIsoLanguage() {
    switch (i18n.global.locale) {
        case "fr":
            return "fr-FR"
        case "en":
        case "dk":
        case "es":
        case "fi":
        case "no":
        case "se":
            return "en-GB"
        case "de":
            return "de-DE"
        case "nl":
        case "fl":
            return "nl-NL"
    }
}
