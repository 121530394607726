import auth from './modules/auth'
import account from './modules/account'
import residences from './modules/residences'

export default {
  resetAll (state, payload) {
    state.account = account.state()
    state.auth = auth.state()
    state.residences = residences.state()
  },
  disconnect (state, payload) {
    state.account = account.state()
    state.auth = auth.state()
    state.residences = residences.state()
  }
}
