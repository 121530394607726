
export default {
    data() {
        return {
            // Data for request
            lng: 'fr',
            // URL for notices
            UrlNotices: 'https://intratone.uk.com/resources/',
            // URL for transfert
            UrlTransfert: 'https://www.intratone.info/pdf/TransfertDeResidence_ex_en.pdf',
            //URL for Bon SAV
            UrlBonSAV: 'https://dwn.intratone.info/documents/bon-retour-sav-en.pdf',
            //URL for Conditions Generales
            UrlConditions: 'https://dwn.intratone.info/conditions/CGU-01-01-en.pdf',

        }
    },
    methods: {
        findLang() {
            let lang = window.location.pathname.replace(new RegExp('/', 'g'), '')
            return lang
        },
        getNotices() {
            this.lng = this.findLang();

            switch (this.lng) {
                case 'fr':
                case 'ch-fr':
                case 'lu-fr':
                case 'ex-fr':
                    this.UrlNotices = 'https://www.intratone.fr/documentation/';
                    break;
                case 'nl':
                case 'tmpnl':
                    this.UrlNotices = 'https://www.intratone.nl/documentatie/';
                    break;
                case 'be-fr':
                    this.UrlNotices = 'https://www.intratone.be/fr/documentation/';
                    break;
                case 'be-fl':
                    this.UrlNotices = 'https://www.intratone.be/documentatie/';
                    break;
                case 'de':
                case 'tmpde':
                    this.UrlNotices = 'https://www.intratone.de/downloads/';
                    break;
                default :
                    this.UrlNotices = 'https://intratone.uk.com/resources/';
                    break;
            }

            return this.UrlNotices
        },

        //@FIX lien vers l'ancien site
        //Utilisé que pour le FR
        getOldWebSite() {
            // utilité d'utiliser la locale ? this.$i18n.locale
            // utilité d'utiliser le lien en dur ? VUE_APP_PHP_SITE_URL
            const lang = location.pathname.split('/')[1].substring(0,2)
            this.UrlOldWebsite = `https://intratone.info/${lang}3`;
            return this.UrlOldWebsite
        },

        getTransfert() {
            this.lng = this.findLang();

            switch (this.lng) {
                case 'fr':
                case 'ch-fr':
                case 'ex-fr':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence.pdf';
                    break;
                case 'lu-fr':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_lu_fr.pdf';
                    break;
                case 'lu-de':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_lu_de.pdf';
                    break;
                case 'be-fr':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_be_fr.pdf';
                    break;
                case 'be-fl':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_be_fl.pdf';
                    break;
                case 'nl':
                case 'tmpnl':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_nl.pdf';
                    break;
                case 'de':
                case 'tmpde':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_de.pdf';
                    break;
                case 'ch-de':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_ch_de.pdf';
                    break;
                case 'en':
                case 'tmpen':
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_en.pdf';
                    break;
                default :
                    this.UrlTransfert = 'https://www.intratone.info/pdf/TransfertDeResidence_ex_en.pdf';
                    break;
            }

            return this.UrlTransfert
        },

        getBonSAV() {
            this.lng = this.findLang();

            switch (this.lng) {
                case 'fr':
                case 'tmpfr':
                case 'ch-fr':
                case 'lu-fr':
                case 'ex-fr':
                case 'be-fr':
                    this.UrlBonSAV = 'https://dwn.intratone.info/documents/bon-retour-sav.pdf';
                    break;
                case 'nl':
                case 'tmpnl':
                case 'be-fl':
                    this.UrlBonSAV = 'https://dwn.intratone.info/documents/bon-retour-sav-nl.pdf';
                    break;
                case 'de':
                case 'ch-de':
                case 'lu-de':
                case 'tmpde':
                    this.UrlBonSAV = 'https://dwn.intratone.info/documents/bon-retour-sav-de.pdf';
                    break;
                default :
                    this.UrlBonSAV = 'https://dwn.intratone.info/documents/bon-retour-sav-en.pdf';
                    break;
            }

            return this.UrlBonSAV
        },

        getConditions() {
            this.lng = this.findLang();
            let ext = '-en';

            switch (this.lng) {
                case 'fr':
                    ext = '-fr';
                    break;
                case 'be-fr':
                case 'lu-fr':
                case 'ch-fr':
                    ext = '-fre';
                    break;
                case 'de':
                case 'tmpde':
                case 'ch-de':
                case 'lu-de':
                    ext = '-de';
                    break;
                case 'nl':
                    ext = "-nl";
                    break;
                default:
                    ext = '-en';
                    break;
            }
            this.UrlConditions = 'https://dwn.intratone.info/conditions/CGU-01-01' + ext + '.pdf'

            return this.UrlConditions
        },

    }
}
